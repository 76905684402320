* {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

#root {
  display: flex;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: var(--final-opacity, 1);
  }
}

@keyframes setBackground {
  0% {
    background: rgba(255, 255, 255, 0);
    border: 1px solid rgba(0, 0, 0, 0);
  }
  100% {
    background: white;
    border: 1px solid rgba(0, 0, 0, 1);
  }
}
